import { TeamInvitation } from "@fscrypto/domain/team-invitation";
import { useActorFromSystem } from "~/state/system";
import { type ProfileManagerActor } from ".";
import { Team, TeamEditOrCreate } from "@fscrypto/domain/team";
import { Context } from "./machine";

// the app state is initialized in __shell.tsx
export const useProfileManager = (teams?: Team[]) => {
  // You can either useActor and create the actor from the hook, or `useActorFromSystem` to get an existing actor
  const [state, ref] = useActorFromSystem<ProfileManagerActor>("profile-manager");
  if (!ref || !state)
    return {
      isReady: false,
      teams: teams || [],
    } as const;

  return {
    isReady: true,
    teams: state.context.teams,
    invites: state.context.invites.filter((invite) => invite.status === "pending"),
    isCreatingTeam: state.matches("creatingTeam"),
    isDeletingPendingInvitation: state.matches("decliningPendingInvitation"),
    isAcceptingPendingInvitation: state.matches("acceptingPendingInvitation"),
    acceptInvitation: (invitation: TeamInvitation) =>
      ref.send({
        type: "PROFILE_MANAGER.ACCEPT_PENDING_INVITATION",
        payload: invitation,
      }),
    declineInvitation: (invitationId: string) =>
      ref.send({ type: "PROFILE_MANAGER.DECLINE_PENDING_INVITATION", payload: { invitationId } }),
    createTeam: (team: TeamEditOrCreate, callback: Context["callback"]) => {
      ref.send({
        type: "PROFILE_MANAGER.CREATE_TEAM",
        payload: team,
        callback,
      });
    },
  };
};
